<template>
  <div class="contact">
    <div class="fullpage">
      <HeaderComponent></HeaderComponent>
      <div class="contianer-fluid content">
      <h1 class="text-center">Contact Us</h1>
      <h2 class="yellow text-center">7 Years</h2>
      <h2 class="text-center">WE ARE BRIDGING BORDERS</h2>
      <p class="text-center">Kedu is also known as Hello ub the Igbo Language of Nigeria. <br/> KeduTV is a 24 hrs Afrobeat Music Channel streaming on Samsung, LG, Roku, FireTV and Mobile Apps</p>
      <div class="row">
  <div class="mx-auto col-10 col-md-8 col-lg-4">
      <div class="container">
      <form class="contact-form" @submit.prevent="submit">
      <div class="error-message">
        <p v-show="!email.valid">Oh, please enter a valid email address.</p>
      </div>
      <fieldset>
        <div class="form-group col-md-12">
          <label class="label" for="name">Name</label>
          <input type="text" name="name" id="name" required="" placeholder="Please enter your name" v-model="name">
        </div>
        <div class="form-group col-md-12">
          <label class="label" for="email">Email</label>
          <input type="email" name="email" placeholder="Please enter your email" id="email" required=""
                :class="{ email , error: !email.valid }"
                v-model="email.value">
        </div>
        <div class="form-group col-md-12">
          <label class="label" for="phone">Phone</label>
          <input type="phone" name="phone" placeholder="Please enter your phone number" id="phone" >
        </div>
        <div class="form-group col-md-12">
          <label class="label" for="textarea">Message</label>
          <textarea class="message" name="textarea" id="textarea" placeholder="please enter your message" required="" 
                    v-model="message.text" 
                    :maxlength="message.maxlength"></textarea>
          <span class="counter">{{ message.text.length }} / {{ message.maxlength }}</span>
        </div>
      </fieldset>
        <div class="mt-2">
          <button type="submit"  class="btn" >Submit</button>
        </div>
     </form>
     </div>
     </div>
     </div>
     </div>
    </div>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
/* eslint-disable */
import FooterComponent from '@/components/footer.vue'
import HeaderComponent from '@/components/header.vue'
var emailRegExp = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export default {
  name: 'contact us',
  data() {
    return {
      title: 'contact us',
      name: "",
      email: {
        value: "",
        valid: true
      },
      message: {
        text: ``,
        maxlength: 255
      },
      submitted: false
    }
  },
  components: {
    FooterComponent,
    HeaderComponent
  },
  async mounted() {
  },
  methods: {
    // submit form handler
    submit: function() {
      this.submitted = true;
    },
    // validate by type and value
    validate: function(type, value) {
      if (type === "email") {
        this.email.valid = this.isEmail(value) ? true : false;
      }
    },
    // check for valid email adress
    isEmail: function(value) {
      return emailRegExp.test(value);
    }
  },
  watch: {
    // watching nested property
    "email.value": function(value) {
      this.validate("email", value);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.fullpage {
  overflow-x: hidden;
}

ul {
  list-style: none;
  list-style-type: none;
  float: left;
}

/************ contact us form  ************/

.yellow {
  color: #FFFD55;
}
.content {
  /* float: left; */
  margin-top: 2rem;
  color: #ecf0f1;
  /* text-align: justify; */
  /* margin: 2rem; */
}
.contact-form {
  font-size: 16px;
  /* width: 500px; */
  /* float:left; */
}
.contact-form fieldset {
  margin: 4px 0 0 0;
  border:none;
}

.contact-form h4,
.contact-form .label {
  margin-bottom: 10px;
}
.contact-form .label {
  display: block;
}
.contact-form input,
.contact-form textarea,
.contact-form select,
.contact-form label {
  color: #fffff1;
}
.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea,
.contact-form select,
.contact-form legend {
  display: block;
  width: 100%;
  appearance: none;
}
.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form input[type="phone"],
.contact-form textarea,
.contact-form select {
  padding: 12px;
  outline: none;
  border: none;
  background-color: #372A48;
  border-radius: 0.25em;
}
.contact-form input[type="text"]:focus,
.contact-form input[type="email"]:focus,
.contact-form input[type="phone"]:focus,
.contact-form textarea:focus,
.contact-form select:focus {
  outline: none;
}
.contact-form .select {
  position: relative;
}
.contact-form .select::after {
  content: "";
  position: absolute;
  z-index: 1;
  right: 16px;
  top: 50%;
  margin-top: -8px;
  display: block;
  width: 16px;
  height: 16px;
  pointer-events: none;
}
.contact-form select {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}
.contact-form select::-ms-expand {
  display: none;
}

.contact-form textarea {
  min-height: 120px;
  resize: vertical;
  overflow: auto;
}
.contact-form button {
  border: none;
  background: #EB4367;
  border-radius: 0.25em;
  padding: 12px 20px;
  color: #ecf0f1;
  font-weight: bold;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  appearance: none;
}
.no-touch .contact-form button:hover {
  background: #372A48;
}
.contact-form button:focus {
  outline: none;
  background: #EB4367;
}
.contact-form button:active {
  transform: scale(0.9);
}
.contact-form .error-message {
  height: 25px;
  margin: 0px;
}
.contact-form .error-message p {
  background: #EB4367;
  color: #ecf0f1;
  font-size: 1rem;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 0.25em;
  padding: 5px;
}
.contact-form .error {
  border-color: #EB4367 !important;
}
.contact-form .counter {
  background-color: #372A48;
  color:#ecf0f1;
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 10px;
  padding: 4px;
}


@-webkit-keyframes cd-bounce {
  0%,
  100% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.8);
  }
}
@-moz-keyframes cd-bounce {
  0%,
  100% {
    -moz-transform: scale(1);
  }
  50% {
    -moz-transform: scale(0.8);
  }
}
@keyframes cd-bounce {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
}

</style>
