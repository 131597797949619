<template>
    <div class="home">
        <div class="fullpage">
            <HeaderComponent></HeaderComponent>
            <div class="main--container">
                <div class="row banner--player--section">
                    <ul class="col-md-12 col-sm-12 col-lg-7 bannerlisthomepage pr-lg-30 " v-if="liveTvCallResponse != null">
                        <li v-for="(item, index) in bannerCallApiResponse" :key="item.id">
                            <img :src="item.image" class="bannerimages " :style="[
                                index == 0
                                    ? { display: 'block' }
                                    : { display: 'none' },
                            ]" />
                        </li>
                    </ul>
                    <div class="col-md-12 col-sm-12 col-lg-5 playerscreenlive" v-if="liveTvCallResponse != null">
                        <!-- <div
                            class="loadingsignchannel"
                            v-show="ischannelLoading"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                style="
                                    margin: auto;
                                    background: none;
                                    display: block;
                                    shape-rendering: auto;
                                "
                                width="32px"
                                height="32px"
                                viewBox="0 0 100 100"
                                preserveAspectRatio="xMidYMid"
                            >
                                <circle
                                    cx="50"
                                    cy="50"
                                    r="34"
                                    stroke-width="8"
                                    stroke="#EB4664"
                                    stroke-dasharray="53.40707511102649 53.40707511102649"
                                    fill="none"
                                    stroke-linecap="round"
                                >
                                    <animateTransform
                                        attributeName="transform"
                                        type="rotate"
                                        repeatCount="indefinite"
                                        dur="0.5434782608695652s"
                                        keyTimes="0;1"
                                        values="0 50 50;360 50 50"
                                    ></animateTransform>
                                </circle>
                            </svg>
                            <span> Loading...</span>
                        </div> -->
                        <!-- <video-player :options="videoOptions" :classes="'custom-width-height'" />  -->
                        <fluid-player :src="src" v-if="src" :vast_ad="vastTagUrl" />
                        <!-- <div class="liveplayer" @click="togglePausePlay">
                            <video
                                ref="video"
                                class="channelplayer"
                                autoplay
                                @loadeddata="startplaying"
                            ></video>
                            <div
                                class="fullScreenIcon"
                                @click="toggleFullscreen"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 512 512"
                                    fill="#d3d0d8"
                                    xmlns:v="https://vecta.io/nano"
                                >
                                    <path
                                        d="M0 0v512h512V0H0zm477.867 477.867H34.133V34.133h443.733v443.734zM126.533 102.4h72.578V68.267H68.267v130.844H102.4v-72.573l96.022 96.02 24.134-24.135zm96.024 211.181l-24.135-24.136-96.022 96.022v-72.578H68.267v130.844h130.844V409.6h-72.573zm187.043-.692v72.578l-96.022-96.023-24.134 24.134 96.018 96.022h-72.573v34.133h130.844V312.889zM312.889 68.267V102.4h72.578l-96.023 96.023 24.134 24.135 96.022-96.02v72.573h34.133V68.267z"
                                    />
                                </svg>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="row">
                    <div class="livebottomsection">
                        <div class="timerow py-2">
                            <div class="epgcontentguide">
                                <div class="epgguiderowcont">
                                    <ul class="tvguidetimelists" v-if="
                                        liveTvCallResponse != null &&
                                        programGuideResponse != null
                                    ">
                                        <li>
                                            <div class="timespans">TV Guide</div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="206" height="54"
                                                viewBox="0 0 308 80">
                                                <rect id="tileback" data-name="tileback-pre" width="308" height="80" rx="5"
                                                    fill="#3d3565" />
                                            </svg>
                                        </li>
                                        <li v-for="(
                                                        program, index
                                                    ) in programGuideResponse" v-bind:key="index">
                                            <div class="timespans">
                                                <span>{{ program.start_time }} -
                                                    {{ program.end_time }}</span>
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="206" height="54"
                                                viewBox="0 0 308 80">
                                                <rect id="tileback" data-name="tileback-pre" width="308" height="80" rx="5"
                                                    fill="#3d3565" />
                                            </svg>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="epgcontent py-2">
                            <ul class="channellistguide" v-if="liveTvCallResponse != null">
                                <li v-if="liveTvCallResponse">
                                    <div class="channelnameepg">
                                        <img :src="liveTvCallResponse.image" />
                                        <div class="arrowepg">
                                            <svg xmlns="http://www.w3.org/2000/svg" :width="7.945" height="14"
                                                viewBox="0 0 11.858 20.905">
                                                <path id="Icon" data-name="icon"
                                                    d="M2.2,13.5H20.292a1.4,1.4,0,0,1,.991,2.4l-9.042,9.049a1.409,1.409,0,0,1-1.99,0L1.209,15.9A1.4,1.4,0,0,1,2.2,13.5Z"
                                                    transform="translate(-13.5 21.699) rotate(-90)" fill="#fff" />
                                            </svg>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="206" height="54"
                                            viewBox="0 0 308 80">
                                            <rect id="tileback" data-name="tileback-pre" width="308" height="80" rx="5"
                                                fill="#3d3565" />
                                        </svg>
                                    </div>
                                    <div class="epgdatarowcont">
                                        <ul class="epgdatarow" v-if="programGuideResponse != null">
                                            <li v-for="(
                                                            program, index
                                                        ) in programGuideResponse" v-bind:key="index">
                                                <div class="epgdata">
                                                    <div class="timespans">
                                                        <span>{{ program.title }}</span>
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="206" height="54"
                                                        viewBox="0 0 308 80">
                                                        <rect id="tileback" data-name="tileback-pre" width="308" height="80"
                                                            rx="5" fill="#3d3565" />
                                                    </svg>
                                                </div>
                                            </li>
                                        </ul>
                                        <ul class="noDataAvailable" v-else>
                                            <li class="noepg">
                                                <span>No Date Available</span>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="tvseriescontentsection" v-if="liveTvCallResponse != null">
                        <div class="tvseriestopline">
                            <router-link class="nav-link vodheading" to="/tvshows">TV Shows</router-link>
                            <div class="lineSeperator"></div>
                            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="76%" height="1" viewBox="0 0 1575 1">
            <path id="Path_53" data-name="Path 53" d="M-120,0H1765" transform="translate(120 0.5)" fill="none" stroke="#d3d0d8" stroke-width="1"/>
          </svg> -->
                            <router-link class="nav-link seeall" to="/tvshows">
                                <span class="seealltext">See All</span>
                            </router-link>
                        </div>
                        <br />

                        <!-- <div class="tvseriescontentrow">
                    <div
                        class="tvseriescontent"
                        v-for="(item, index) in tvseriesApiResponse"
                        v-bind:key="index"
                    >
                        <router-link
                            :to="{
                                name: 'tvshowsdetail',
                                params: { id: item.id },
                            }"
                        >
                            <img class="tv-img-size" :src="item.image" />
                            <div class="itemname">
                                <span class="itemtext">{{
                                    item.name
                                }}</span>
                            </div>
                        </router-link>
                    </div>
                </div>
                <button class="right--arrow--button"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
              </svg></button> -->
                    </div>

                </div>
                <div class="tvseries--container">
                    <div class="tvseries--left--container">
                        <button class="left--arrow--button">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                                class="bi bi-caret-left-fill" viewBox="0 0 16 16">
                                <path
                                    d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
                            </svg>
                        </button>
                        <div class="tvseries--list ">
                            <div class="tvseries--episode--card" v-for="(item, index) in tvseriesApiResponse" :key="index">
                                <router-link class="tvseries--episode--link"
                                    :to="{ name: 'tvshowsdetail', params: { id: item.id } }">
                                    <div>
                                        <img :src="item.image" alt="">
                                    </div>
                                    <p>{{ item.name }}</p>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <button class="right--arrow--button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                            class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                            <path
                                d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                        </svg>
                    </button>
                </div>
            </div>
            <FooterComponent></FooterComponent>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import $ from "jquery";
// import VideoPlayer from "./video.vue";
import FluidPlayer from "./fluidplayer.vue";
import FooterComponent from "@/components/footer.vue";
import HeaderComponent from "@/components/header.vue";
export default {
    name: "home",
    data() {
        return {
            title: "Home",
            homePageBannersApiUrl:
                "https://api.kedutv.com/public/index.php/banners/sections/homepage",
            liveTvApiUrl: "https://api.kedutv.com/public/index.php/channel",
            programGuideUrl:
                "https://api.kedutv.com/public/index.php/channel/showEpg",
            tvseriesApiUrl:
                "https://api.kedutv.com/public/index.php/category?limit=4",
            bannerCallApiResponse: null,
            programGuideResponse: [],
            liveTvCallResponse: null,
            isPlaying: false,
            tvseriesApiResponse: null,
            sliderInterval: null,
            timeSlotIndex: -1,
            ischannelLoading: true,
            src: null,
            vastTagUrl: null,
            videoOptions: {
                autoplay: true,
                controls: true,
                loop: true,
                poster: null,
                sources: [
                    {
                        thumb: null,
                        src: null
                    }
                ]
            }
        };
    },
    components: {
        FooterComponent,
        HeaderComponent,
        // VideoPlayer,
        FluidPlayer
    },
    async mounted() {
        await this.loadPageData();
        let self = this;
        setTimeout(function () {
            let stream = self.liveTvCallResponse?.link;
            self.src = stream;
            self.vastTagUrl = self?.liveTvCallResponse?.vast_ad;
            if (stream && !self?.videoOptions?.sources[0]?.src) {
                self.videoOptions.sources[0].src = stream;
                self.videoOptions.poster = self.liveTvCallResponse.image;
            }
        }, 1000);
    },
    methods: {
        loadPageData: function () {
            // Banners Call Home
            this.homePageBannersApi();
            this.setBannerSlider();
            //live tv call
            this.liveTvApi();
            //tvseries call
            this.tvseriesContentApi();
            this.enableEpgDragger(".tvseries--list");
            this.enableEpgDragger(".livebottomsection");
            this.leftrightscroll();
        },
        leftrightscroll: function () {
            const leftArrowButton = document.querySelector('.left--arrow--button');
            const rightArrowButton = document.querySelector('.right--arrow--button');
            const allSeasonsContainer = document.querySelector('.tvseries--list');

            leftArrowButton.addEventListener('click', function () {
                allSeasonsContainer.scrollLeft -= 315; // Scroll left by 100px
            });

            rightArrowButton.addEventListener('click', function () {
                allSeasonsContainer.scrollLeft += 315; // Scroll right by 100px
            });
        },
        enableEpgDragger: function (classname) {
            const slider = document.querySelector(classname);
            let isDown = false;
            let startX;
            let scrollLeft;
            if (!slider) return;
            slider.addEventListener("mousedown", (e) => {
                isDown = true;
                slider.classList.add("active");
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            });
            slider.addEventListener("mouseleave", () => {
                isDown = false;
                slider.classList.remove("active");
            });
            slider.addEventListener("mouseup", () => {
                isDown = false;
                slider.classList.remove("active");
            });
            slider.addEventListener("mousemove", (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 3; //scroll-fast
                slider.scrollLeft = scrollLeft - walk;
                console.log(walk);
            });
        }
        ,

        // startplaying: function (e) {
        //     let video = document.querySelector("video");
        //     let self = this;
        //     this.ischannelLoading = false;
        //     setTimeout(function () {
        //         video.play();
        //         self.isPlaying = true;
        //     }, 500);
        // },
        // toggleFullscreen: function () {
        //     var elem = document.querySelector("video");
        //     if (elem.requestFullscreen) {
        //         elem.requestFullscreen();
        //     } else if (elem.webkitRequestFullscreen) {
        //         /* Safari */
        //         elem.webkitRequestFullscreen();
        //     } else if (elem.msRequestFullscreen) {
        //         /* IE11 */
        //         elem.msRequestFullscreen();
        //     }
        // },
        // togglePausePlay: function () {
        //     let video = document.querySelector("video");
        //     if (this.isPlaying == true) {
        //         this.isPlaying = false;
        //         video.pause();
        //     } else if (this.isPlaying == false) {
        //         this.isPlaying = true;
        //         video.play();
        //     }
        // },

        homePageBannersApi: function () {
            var self = this;
            this.isloading = true;
            var settings = {
                url: this.homePageBannersApiUrl,
                method: "GET",
                timeout: 15000,
                processData: false,
                mimeType: "multipart/form-data",
                contentType: false,
            };
            $.ajax(settings)
                .done(function (response) {
                    self.bannerCallApiResponse = JSON.parse(response);
                    self.isloading = false;
                })
                .fail(function (xhr) {
                    self.isloading = false;
                    self.bannerCallApiResponse = null;
                    setTimeout(function () {
                        //live tv call
                        self.homePageBannersApi();
                    }, 5000);
                });
        },
        setBannerSlider: function () {
            if (this.sliderInterval == null)
                this.sliderInterval = setInterval(this.nextSliderItem, 5000);
        },
        tvseriesContentApi: function () {
            var self = this;
            this.isloading = true;
            var form = new FormData();
            form.append("limit", "5");
            var settings = {
                url: this.tvseriesApiUrl,
                method: "POST",
                timeout: 15000,
                processData: false,
                mimeType: "multipart/form-data",
                contentType: false,
                data: form,
            };
            $.ajax(settings)
                .done(function (response) {
                    self.tvseriesApiResponse = JSON.parse(response);
                    self.isloading = false;
                })
                .fail(function (xhr) {
                    self.isloading = false;
                    self.tvseriesApiResponse = null;
                    setTimeout(function () {
                        //live tv call
                        self.tvseriesContentApi();
                    }, 5000);
                });
        },
        liveTvApi: function () {
            var self = this;
            this.isloading = true;
            var settings = {
                url: this.liveTvApiUrl,
                method: "GET",
                timeout: 15000,
                processData: false,
                mimeType: "multipart/form-data",
                contentType: false,
            };
            $.ajax(settings)
                .done(function (response) {
                    var jsonDataAll = JSON.parse(response);
                    console.log(jsonDataAll);
                    self.liveTvCallResponse = jsonDataAll[4]; // 4rth one is for web start from 0
                    self.getProgramGuide(self.liveTvCallResponse.id);
                    self.isloading = false;
                })
                .fail(function (xhr) {
                    self.isloading = false;
                    self.liveTvCallResponse = null;
                    setTimeout(function () {
                        //live tv call
                        self.liveTvApi();
                    }, 5000);
                });
        },
        getProgramGuide: function (id) {
            var self = this;
            this.isloading = true;
            var settings = {
                url: this.programGuideUrl + "/" + id,
                method: "GET",
                timeout: 30000,
                processData: false,
                mimeType: "multipart/form-data",
                contentType: false,
            };
            $.ajax(settings)
                .done(function (response) {
                    self.programGuideResponse = JSON.parse(response);
                    var hours = new Date().getHours();
                    var fromslot = hours < 10 ? "0" + hours : hours;
                    self.programGuideResponse.forEach(function (item, index) {
                        if (self.timeSlotIndex == -1) {
                            if (item.start_time.indexOf(fromslot) == 0) {
                                self.timeSlotIndex = index;
                            }
                        }
                    });
                    self.programGuideResponse = self.programGuideResponse.slice(
                        self.timeSlotIndex
                    );
                    self.isloading = false;
                })
                .fail(function (xhr) {
                    self.isloading = false;
                });
        },
        defaultHomeBanner: function (e) {
            e.target.src = "./assets/images/kedu-tv-default-home.png";
            console.log("error in banner load");
        },
        nextSliderItem: function () {
            if (this.bannerCallApiResponse != null) {
                var item = this.bannerCallApiResponse.shift();
                this.bannerCallApiResponse.push(item);
            }
        },
    },
    watch: {
        src: function (val, oldVal) {
        },
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* This will be used for large screens */
@media (min-width: 992px) {
    .bannerlisthomepage {
        padding-right: 20px;
        margin-bottom: 0px;
    }

    .bannerlisthomepage li img {
        height: 400px;
    }

    .banner--player--section {
        height: 400px;
    }

    .playerscreenlive {
        height: 400px !important;
    }
}

.tvseries--container {
    display: flex;
    justify-content: space-between;
    -ms-overflow-style: none; 
    scrollbar-width: none; 
}
.tvseries--left--container::-webkit-scrollbar{
    display: none;
}
.tvseries--left--container{
    display: flex;
    overflow-x: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none; 
}
.tvseries--container::-webkit-scrollbar {
    display: none;
}

.row {
    --bs-gutter-x: 0rem;
    justify-content: space-between;
}

.main--container {
    margin: 0vw 7vw 0vw 7vw;
}
.tvseries--list::-webkit-scrollbar {
    display: none;
}
.tvseries--list {
    display: flex;
    column-gap: 2rem;
    overflow-x: scroll;
    cursor: grab;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}



.tvseries--episode--card img {
    width: auto;
    height: 9.375rem;
    cursor: pointer;
}

.tvseries--episode--card {
    text-align: center;
}

.tvseries--episode--card a {
    color: white;
    font-size: large;
    font-weight: bold;
}

.tvseries--episode--card a:hover {
    color: #eb4664;
}

/* * {
    box-sizing: border-box;
    border: 1px solid red;
} */

.fullpage {
    width: 100%;
}

.left--arrow--button {
    position: relative;
    bottom: 22px;
    color: white;
    background: transparent;
    font-size: 0.9rem;
    font-weight: 600;
    border: none;
}

.right--arrow--button {
    position: relative;
    bottom: 22px;
    color: white;
    background: transparent;
    font-size: 0.9rem;
    font-weight: 600;
    border: none;
}

.fullScreenIcon {
    z-index: 99999;
    position: absolute;
    float: right;
    margin-top: -3rem;
    margin-left: 1rem;
    margin-right: 1rem;
    opacity: 0.7;
    cursor: pointer;
}

.listseries {
    display: inline-block;
}

.fullScreenIcon svg:hover {
    fill: #eb4664;
}

ul {
    list-style: none;
    list-style-type: none;
    padding-inline-start: 0px;
}

.fullscreen {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    min-width: 100%;
    min-height: fit-content;
}

.center {
    display: flex;
    justify-content: center;
}

.bannerimages {
    width: 100%;
    height: 100%;
}



.custom-width-height {
    width: 100%;
    height: clamp(15rem, 30vw, 22rem);
}

.loadingsignchannel {
    position: absolute;
    margin-top: 8rem;
    margin-left: 17em;
    z-index: 9999;
}

.loadingsignchannel span {
    color: #fff;
    font-size: 9.38px;
}

.channelplayer {
    height: auto;
    width: 100%;
}

video::-webkit-media-controls-panel {
    display: none;
}

.livebottomsection {
    height: 10rem;
    cursor: grab;
    width: 100%;
    display: flow-root;
    justify-content: center;
    margin-top: 2rem;
    overflow-x: scroll;
}

.livebottomsection.active {
    cursor: grabbing;
    cursor: -webkit-grabbing;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.livebottomsection::-webkit-scrollbar {
    display: none;
}

.lineSeperator {
    flex: 1;
    border-bottom: 1px solid #d3d0d8;
    height: 16px;
    float: left;
}

/* Hide scrollbar for IE, Edge and Firefox */
.livebottomsection {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.channellistguide {
    position: relative;
    overflow: hidden;
    list-style: none;
    height: max-content;
    height: -moz-max-content;
    height: -webkit-max-content;
    margin-top: 0rem;
    transition: margin-top 0.1s linear;
}

.channellistguide li img {
    height: 3rem;
    position: absolute;
    margin-left: 3.3rem;
}

.epgdatarowcont {
    margin-left: 13.4rem;
    margin-top: -0.7rem;
}

.epgdatarow {
    float: left;
    width: max-content;
    width: -moz-max-content;
    width: -webkit-max-content;
    list-style: none;
    position: relative;
    margin-left: 0rem;
    margin-top: -3rem;
    transition: margin-left 0.1s linear;
}

.epgdatarow li {
    float: left;
    margin-right: 0.5rem;
}

.arrowepg {
    position: absolute;
    margin-top: 1rem;
    margin-left: 12rem;
}

.programdetails {
    position: absolute;
    font-family: Mont-Bold;
    color: #fff;
    margin-top: 4rem;
    margin-left: 3rem;
}

.programname {
    font-family: MontBold;
    font-size: 36px;
    margin-bottom: 0.5rem;
    width: 26rem;
    height: 1.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.programairtime {
    font-size: 24px;
    font-family: MontRegular;
    margin-bottom: 0.5rem;
}

.descriptionprogram {
    text-align: justify;
    font-size: 24px;
    font-family: MontRegular;
    margin-bottom: 0.5rem;
    width: 38rem;
    height: 7rem;
    overflow: hidden;
}

.channelnameepg {
    width: 14.5rem;
}

.noDataAvailable {
    color: #fff;
    font-size: 18px;
    list-style: none;
    position: absolute;
}

.noepg {
    margin-top: -3rem;
    margin-left: 35rem;
}

.timespans {
    position: absolute;
    margin-left: 1.2rem;
    margin-top: 1rem;
    text-align: center;
    width: 10rem;
    height: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rightshadeepg {
    float: right;
    z-index: 999999;
    pointer-events: none;
    position: absolute;
    margin-left: 92.5%;
}

.timerow {
    color: #fff;
    font-size: 15px;
    float: left;
    font-family: MontBold;
}

.tvguideheading {
    list-style: none;
    float: left;
    position: relative;
    z-index: 99999;
}

.timerow ul.tvguidetimelists {
    width: max-content;
    width: -moz-max-content;
    width: -webkit-max-content;
    list-style: none;
    position: relative;
    margin-left: 0rem;
    transition: margin-left 0.1s linear;
}

.timerow ul.tvguidetimelists li {
    float: left;
    margin-right: 0.5rem;
    width: auto;
}

.epgcontentguide {
    color: #fff;
    font-size: 15px;
    float: left;
    font-family: MontBold;
    overflow: hidden;
}

.epgcontent {
    color: #fff;
    font-size: 15px;
    float: left;
    font-family: MontBold;
    overflow: hidden;
}

.tvseriescontentsection {
    width: 100%;
    text-align: center;
}

.tvseriestopline {
    display: flex;
    margin: 2px;
    justify-content: center;
    gap: 0.5rem;
}

.vodheading {
    color: #ffffe0;
    font-size: 16.08px;
    float: left;
    font-family: MontBold;
    margin-right: 0.134rem;
    text-decoration: none;
}

.vodheading:hover,
.seeall:hover {
    color: #eb4664;
    cursor: pointer;
    text-decoration: none;
}

.seeall {
    color: #ffffe0;
    text-decoration: none;
    font-size: 16.08px;
    float: left;
    font-family: MontRegular;
    margin-left: 0.134rem;
}

.tvseriescontentrow {
    display: flex;
    justify-content: space-around;
    gap: 0.5rem;
    overflow-x: scroll;
}

.tvseriescontenlist li {
    float: left;
    margin-right: 2.345rem;
    list-style-type: none;
    text-align: center;
    text-decoration: none;
}

.tvseriescontenlist li img {
    width: 18rem;
    height: 12rem;
    border-radius: 0.737rem;
    border: solid;
    border-width: 0.0469rem;
    border-color: #4e4e4e;
}

.list-group-item:hover {
    /* border-style: solid !important;
  border-color: #eb4664 !important;
  border-radius: 0.5em !important;
  border-width: 0.2rem !important; */
    cursor: pointer;
    color: #eb4664 !important;
}

a {
    text-decoration: none;
}

.tvseriescontenlist {
    width: -moz-max-content;
    /* Firefox/Gecko */
    width: -webkit-max-content;
    /* Chrome */
    width: max-content;
    float: left;
    margin-top: 2rem;
}

/* .itemname {
    overflow: hidden;
} */

.itemtext {
    color: #fff;
}

.tv-img-size {
    width: 24rem;
    height: 12rem;
    border-radius: .4rem;
}

/* 
@media (max-width: 1280px) {
    .tv-img-size {
        width: 16rem;
    }
}

@media (max-width: 1024px) {
    .tv-img-size {
        width: 16rem;
    }
}

@media (max-width: 768px) {
    .tv-img-size {
        width: 18rem;
    }
} */</style>