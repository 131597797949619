<template>
  <b-navbar toggleable="lg" type="dark" class="navbar-container">
        <b-navbar-brand href="#" class="logoleft"></b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav right>
            <b-nav-item> <router-link class="nav-link" to="/">HOME</router-link></b-nav-item>
            <b-nav-item> <router-link class="nav-link" to="/tvshows">TV SHOWS</router-link></b-nav-item>
            <b-nav-item> <router-link class="nav-link" to="/news">NEWS</router-link></b-nav-item>
            <b-nav-item> <router-link class="nav-link" to="/contact">CONTACT US</router-link></b-nav-item>
            <b-nav-item> <router-link class="nav-link" to="/aboutus">ABOUT US</router-link></b-nav-item>
          </b-navbar-nav>
        </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  /* eslint-disable */
  name: 'Header',
  data() {
    return {
      title: 'header'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar-container {
  display: flex;
  margin: 0vw 6.8vw 0vw 7vw;
}
@media (min-width: 992px){
.navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    justify-content: flex-end;
}
}

/* .navbar {
  justify-content: flex-end;
  margin-right: 3.1%;
} */
.logo {
  width: auto;
  height: 3rem;
}

.menues {
  width: 95%;
  display: flex;
  justify-content: center;
}

.nav-link {
  font-size: 14px;
  color: white;
  text-decoration: none !important;
}
.nav-link:visited{
  color: white;
}
.nav-link:hover{
  color: #eb4664;
}
.nav-link:active{
  color: #eb4664;
}
.logoleft {
  justify-content: flex-start;
  margin-top: 10px;
  width: 15%;
  margin-bottom: 1%;
  height: 3rem;
  background: url(../assets/images/logo.png) no-repeat;
  background-size: contain;
  float: left;
}

/* @media only screen and (max-width: 1020px) {
  .logoleft {
    margin-right: 35%;
  }
} */

.menues ul {
  float: right;
}

.menues ul li {
  display: inline-table;
  padding-right: 40px;
  margin-top: 10px;
}

.menues ul li a {
  text-decoration: none;
  font-size: 0.9rem;
  color: #d3d0d8;
}

.menues ul li a:hover {
  text-decoration: none;
  color: #eb4664;
}

.menues ul li a:active {
  text-decoration: none;
  color: #FFFFE0;
}

.navbar-nav {
  margin-left: 0px;
}</style>
