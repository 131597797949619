<script setup>
import { useQuery } from "@tanstack/vue-query";
import { useQueryParams } from "../helpers/useQueryParams"
const { value: { page} } = useQueryParams();
const fetcher = async () =>
  await fetch(`https://api.kedutv.com/public/index.php/news?${page ? `page=${page}` : ""}`).then(
    async (response) => {
      let mydata = await response.json()
      // let mydescription = mydata.data.find((data) => {
      //   return data.type === 'advanced'
      // }).description
      // console.log(mydescription)
      return mydata
      // let typedata=data.data.find((data)=>{
      //   return data.type==='advanced'
      // })
      // console.log(typedata[0])
    }
  );

const { isLoading, isError, data, error } = useQuery(
  {
    queryKey: ["newslist"],
    queryFn: fetcher,
  }
);

</script>
<template>
  <div class="news">
    <HeaderComponent></HeaderComponent>
    <main>
      <!-- <div class="banner_section">
        <div class="banner">
          <img src="../assets/images/kedu-tv-default-tvshow.png" alt="" />
        </div>
        <div class="banner_description">
          Top 10 oldies but goodies Nigerian music
        </div>
      </div> -->
      <div class="main--content">
        <div class="multiple_news_section">
          <div class="news_section col-lg-7 col-md-8 col-sm-10 col-xs-12">
            <div v-if="isLoading">Loading...</div>
            <div v-else-if="isError">An error has occurred: {{ error }}</div>
            <div v-else-if="data">
              <!-- <div class="news_card">
                    <div class="news_card_image">
                      <img src="../assets/images/sample_image.jpg" alt="">
                    </div>
                    <div class="news_card_detail">
                      <div class="card_title_section">
                        <div class="individual_card_title">
                          <a @click="$emit('setPostId', item.id)" href="#">{{ item.title }}</a>
                        </div>
                      </div>
                      <div class="heart_logo">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"
                          class="bi bi-suit-heart" viewBox="0 0 16 16">
                          <path
                            d="m8 6.236-.894-1.789c-.222-.443-.607-1.08-1.152-1.595C5.418 2.345 4.776 2 4 2 2.324 2 1 3.326 1 4.92c0 1.211.554 2.066 1.868 3.37.337.334.721.695 1.146 1.093C5.122 10.423 6.5 11.717 8 13.447c1.5-1.73 2.878-3.024 3.986-4.064.425-.398.81-.76 1.146-1.093C14.446 6.986 15 6.131 15 4.92 15 3.326 13.676 2 12 2c-.777 0-1.418.345-1.954.852-.545.515-.93 1.152-1.152 1.595L8 6.236zm.392 8.292a.513.513 0 0 1-.784 0c-1.601-1.902-3.05-3.262-4.243-4.381C1.3 8.208 0 6.989 0 4.92 0 2.755 1.79 1 4 1c1.6 0 2.719 1.05 3.404 2.008.26.365.458.716.596.992a7.55 7.55 0 0 1 .596-.992C9.281 2.049 10.4 1 12 1c2.21 0 4 1.755 4 3.92 0 2.069-1.3 3.288-3.365 5.227-1.193 1.12-2.642 2.48-4.243 4.38z" />
                        </svg>
                      </div>
                    </div>

                <div class="card_description">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatum fugiat
                  delectus ea! Magnam natus earum amet dolores necessitatibus, soluta, vitae provident illo saepe
                  doloribus nostrum eaque! Ad eligendi iure laboriosam!</div>
              </div> -->
              <div v-for="item in data.data" :key="item.id">
                <div class="news_card" v-b-modal="`modal-${item.id}`">
                  <b-modal :id="`modal-${item.id}`" size="lg" :title="item.title">
                    <div class="news_modal_video">
                      <FluidPlayer :src="item.video" v-if="item.video" :autoPlay="false" :vast_ad="item.vast_ad"
                        :posterImage="item.image" />
                    </div>
                    <h5 class="my-3">{{ item.short_description }}</h5>
                    <div class="des" v-if="item.type === 'advanced'" v-html="convertToHtml(item.description)"></div>
                    <div class="des" v-else>{{ item.description }}</div>
                    <div class="social--links">
                      <div class="social--text">
                        Share This On
                      </div>
                      <div class="social--icons">
                        <ShareNetwork network="facebook" :url="`https://kedutv.com/newsdetail?id=${item.id}`"
                          :title="item.short_description">
                          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#129BF7"
                            class="bi bi-facebook" viewBox="0 0 16 16">
                            <path
                              d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                          </svg>
                        </ShareNetwork>
                        <ShareNetwork network="twitter" :url="`https://kedutv.com/newsdetail?id=${item.id}`"
                          :title="item.short_description">
                          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#1D9BF0"
                            class="bi bi-twitter" viewBox="0 0 16 16">
                            <path
                              d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                          </svg>
                        </ShareNetwork>
                        <ShareNetwork network="linkedin" :url="`https://kedutv.com/newsdetail?id=${item.id}`"
                          :title="item.short_description">
                          <span><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#0A66C2"
                              class="bi bi-linkedin" viewBox="0 0 16 16">
                              <path
                                d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                            </svg></span>
                        </ShareNetwork>
                        <ShareNetwork network="whatsapp" :url="`https://kedutv.com/newsdetail?id=${item.id}`"
                          :title="item.short_description">
                          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#46C254"
                            class="bi bi-whatsapp" viewBox="0 0 16 16">
                            <path
                              d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                          </svg>
                        </ShareNetwork>
                      </div>
                    </div>
                  </b-modal>
                  <div class="news_card_image">
                    <img :src="item.image" alt="" />
                  </div>
                  <div class="news_card_detail">
                    <div class="card_title_section">
                      <div class="individual_card_title">
                                  <button href="#">{{ item.title }}</button>
                      </div>
                      <!-- <div class="heart_logo">
                      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor"
                        class="bi bi-suit-heart" viewBox="0 0 16 16">
                        <path
                          d="m8 6.236-.894-1.789c-.222-.443-.607-1.08-1.152-1.595C5.418 2.345 4.776 2 4 2 2.324 2 1 3.326 1 4.92c0 1.211.554 2.066 1.868 3.37.337.334.721.695 1.146 1.093C5.122 10.423 6.5 11.717 8 13.447c1.5-1.73 2.878-3.024 3.986-4.064.425-.398.81-.76 1.146-1.093C14.446 6.986 15 6.131 15 4.92 15 3.326 13.676 2 12 2c-.777 0-1.418.345-1.954.852-.545.515-.93 1.152-1.152 1.595L8 6.236zm.392 8.292a.513.513 0 0 1-.784 0c-1.601-1.902-3.05-3.262-4.243-4.381C1.3 8.208 0 6.989 0 4.92 0 2.755 1.79 1 4 1c1.6 0 2.719 1.05 3.404 2.008.26.365.458.716.596.992a7.55 7.55 0 0 1 .596-.992C9.281 2.049 10.4 1 12 1c2.21 0 4 1.755 4 3.92 0 2.069-1.3 3.288-3.365 5.227-1.193 1.12-2.642 2.48-4.243 4.38z" />
                      </svg>
                    </div> -->
                    </div>
                    <div class="news_card_description">
                      {{ item.short_description }}
                    </div>
                  </div>
                </div>
                <div class="social--links--all">
                  <div class="social--text">
                    Share This On
                  </div>
                  <div class="social--icons">
                    <ShareNetwork network="facebook" :url="`https://kedutv.com/newsdetail?id=${item.id}`"
                      :title="item.short_description">
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#129BF7" class="bi bi-facebook"
                        viewBox="0 0 16 16">
                        <path
                          d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                      </svg>
                    </ShareNetwork>
                    <ShareNetwork network="twitter" :url="`https://kedutv.com/newsdetail?id=${item.id}`"
                      :title="item.short_description">
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#1D9BF0" class="bi bi-twitter"
                        viewBox="0 0 16 16">
                        <path
                          d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                      </svg>
                    </ShareNetwork>
                    <ShareNetwork network="linkedin" :url="`https://kedutv.com/newsdetail?id=${item.id}`"
                      :title="item.short_description">
                      <span><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#0A66C2"
                          class="bi bi-linkedin" viewBox="0 0 16 16">
                          <path
                            d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                        </svg></span>
                    </ShareNetwork>
                    <ShareNetwork network="whatsapp" :url="`https://kedutv.com/newsdetail?id=${item.id}`"
                      :title="item.short_description">
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#46C254" class="bi bi-whatsapp"
                        viewBox="0 0 16 16">
                        <path
                          d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                      </svg>
                    </ShareNetwork>
                  </div>
                </div>
              </div>

            </div>
            <button class="load_more">Load More</button>
          </div>
        </div>
      </div>
      <!-- <aside>

        <div class="sidebar">
          <h2>Kedu Tv</h2> 
		<nav>
			<a href="#" class="nav-link">Home</a>
			<a href="#" class="nav-link">Tv Shows</a>
			<a href="#" class="nav-link">News</a>
      <a href="#" class="nav-link">About Us</a>
			<a href="#" class="nav-link">Contact Us</a>
		</nav> 
          <div class="episode-list">
            <h3>Newly Added Episodes</h3>
            <div class="episode">
              <div class="episode-title">Episode Title 1</div>
              <img src="https://via.placeholder.com/280x150" alt="Advertisement">
              <div class="episode-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
            </div>
            <div class="episode">
              <div class="episode-title">Episode Title 2</div>
              <img src="https://via.placeholder.com/280x150" alt="Advertisement">
              <div class="episode-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
            </div>
          </div>
          <h2 class="advertisement">Featured</h2>
          <div class="adx-section">
            <img src="https://via.placeholder.com/280x150" alt="Advertisement">
            <p>Get your favorite TV shows delivered straight to your inbox! Subscribe now.</p>
          </div>
          <div class="social-links">
            <a href="#" class="social-link">Facebook</a>
            <a href="#" class="social-link">Twitter</a>
            <a href="#" class="social-link">Instagram</a>
            <a href="#" class="social-link">YouTube</a>
          </div>
        </div>
      </aside> -->
      
    </main>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import FooterComponent from "@/components/footer.vue";
// import edjsHTML from 'editorjs-html';
import HeaderComponent from "@/components/header.vue";
import FluidPlayer from "./fluidplayer.vue";
import { convertToHtml } from "../helpers/editor";
export default {
  name: "newsComponent",
  components: {
    FooterComponent,
    HeaderComponent,
    FluidPlayer,
  },
  data() {
    return {
      modalId: null
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* END  */


.news_modal_image {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.news_modal_image img {
  width: 80%;
}

.social--links--all {
  margin-top: 1rem;
  margin-bottom: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  padding: 0.4rem 0.3rem;
}

.social--links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 1px 0px 10px red;
  background-color: #1F1233;
  color: #EA3E6F;
  padding: 4px 12px;
  margin: 16px 0px;
}

.social--links,
.social--text {
  font-weight: bold;
  font-size: 20px;
}

.social--icons {
  display: flex;
  gap: 16px;
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    --bs-modal-width: 756px;
  }
}

.main--content {
  margin: 0vw 7vw 0vw 7vw;
}

/* newcss  */
.advertisement {
  color: #ea4366;
}

.news_modal_video {
  text-align: center;
}

.news_modal_video img {
  width: 100%;
}

.sidebar {
  box-shadow: 0px 0px red, -0.3rem 0 0.4rem #ea4366;
  /* border-left: 4px solid white; */
  color: white;
  height: 100%;
  background: #1f1233;
  padding: 20px;
  box-sizing: border-box;
}

/* Styling for the navigation links */
.nav-link {
  margin-bottom: 10px;
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
  transition: color 0.2s ease-in-out;
}

.nav-link:hover {
  color: #f44336;
}

/* Styling for the social media links */
.social-link {
  display: inline-block;
  margin-right: 10px;
  color: #ea4366;
  text-decoration: none;
  font-size: 20px;
  transition: color 0.2s ease-in-out;
}

.social-link:hover {
  color: #f44336;
}

/* Styling for the episode list */
.episode-list {
  margin-top: 30px;
}

.episode-list h3 {
  color: #ea4366;
  font-weight: bold;
}

.episode {
  margin-bottom: 10px;
}

.episode-title {
  font-weight: bold;
  margin-bottom: 5px;
  color: #ea4366;
}

.episode-description {
  font-size: 14px;
}

.adx-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.adx-section img {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.adx-section p {
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
}

/* main {
  display: flex;
} */

aside {
  width: 30%;
}

.load_more {
  background-color: #ea4366;
  width: 100%;
  border-style: none;
  color: aliceblue;
  border-radius: 8px;
  padding: 0.5rem 0rem;
}

.multiple_news_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card_title_section {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0rem;
  font-size: 20px;
  color: #ea4366;
}

.individual_card_title button {
  all: unset;
  text-decoration: none;
  color: inherit;
}



.news_card {
  margin: 1rem 0rem;
}

.news_card .news_card_image img {
  width: 100%;
}

main {
  color: white;
}

.banner_section {
  text-align: center;
  font-size: 2rem;
  margin: 1rem 0rem;
  /* letter-spacing: 4px ; */
  color: #ea4366;
  font-weight: bold;
}

img {
  border: 4px solid #ea4366;
  border-radius: 8px;
}

.banner img {
  width: 100%;
  height: clamp(10rem, 70vw, 20rem);
}

ul {
  list-style: none;
  list-style-type: none;
}
</style>
