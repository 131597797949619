<template>
  <div class="footer-container">
    <div class="footer-top">
      <div class="footer-logo">
        <div class="footerlogoleft"></div>
      </div>
      <div class="footer-text">
        <p>Powered by KeduTV LLC © 2022 . All rights reserved.</p>
      </div>
    </div>
    <div class="footer-options pt-3">
      <div class="footerinnercontainer">
        <router-link class="grid-item nav-link" to="/disclaimer" >
          <p>Disclaimer</p>
        </router-link>
        <router-link class="grid-item nav-link" to="/termofservice">
          <p>Term of Service</p>
        </router-link>
        <router-link class="grid-item nav-link" to="/termofuse">
          <p>Term of Use</p>
        </router-link>
        <router-link class="grid-item nav-link" to="/privacypolicy">
          <p>Privacy Policy</p>
        </router-link>
        <router-link class="grid-item nav-link" to="/copyright">
          <p>Copyright Notice</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  /* eslint-disable */
  name: 'Footer',
  data() {
    return {
      title: 'Footer'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer-container {
  margin-top: 3rem;
  background-color: #211336;
  width: 100%;
  overflow: hidden;
  float: left;
}

.footer-logo {
  margin-left: 3rem;
  margin-right: 3rem;
  float: left;
}

.footerinnercontainer a:hover {
  text-decoration: none;
  color: #eb4664;
}

.footerlogoleft {
  width: 8rem;
  height: 4rem;
  background: url(../assets/images/logo.png) no-repeat;
  background-size: contain;
}

.footer-text {
  margin-bottom: 2rem;
}

.footer-text p {
  margin-top: 3rem;
  left: 0;
  bottom: 0;
  font-size: 1rem;
  display: inline;
  color: #AB0443;
  text-align: center;
}

.footer-options {
  background-color: #000000;
  width: 100%;
  left:0;
  position: absolute;
  text-align: center;
}

.footerinnercontainer {
  display: inline-block;
}

.grid-item {
  text-decoration: none;
  color: #ffffff;
  float: left;
  margin-right: 2.5rem;
  margin-left: 2.5rem;
  white-space: nowrap;
}
</style>
