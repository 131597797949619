<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'App'
}
</script>
<style>
html {
  font-size: 100%;
  width: 100%;
  height: 100%
}

body {
  background-color: #1F1233 !important;
}

@font-face {
  font-family: 'MontRegular';
  src: local("MontRegular"), url("./assets/fonts/Mont-Regular.otf") format("truetype");
}

@font-face {
  font-family: 'MontBold';
  src: local("MontBold"), url("./assets/fonts/Mont-Bold.otf") format("truetype");
}

#app {
  font-family: MontRegular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1F1233 !important;
  color: #000000;
  padding: 0;
  margin: auto !important;
}
</style>
