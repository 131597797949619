<template>
    <div class="fullpage">
        <HeaderComponent></HeaderComponent>
        <div class="bannercenter">
            <div v-if="bannerCallApiResponse != null" class="tvseriesbanners">
                <div class="bannerlistvodtvlist">
                    <div
                        v-for="(item, index) in bannerCallApiResponse"
                        v-bind:key="index"
                    >
                        <router-link
                            :to="{
                                name: 'tvshowsdetail',
                                params: { id: item.video_id },
                            }"
                        >
                            <img :src="item.image" />
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="tvseriesApiResponse != null" class=" tvseriescontent">
            <div class="spacer"></div>
            <!-- <div class="contentsection"> -->
                <!-- <div class="leftarrow" @click="enableshowsDragger">
           <svg xmlns="http://www.w3.org/2000/svg" width="20.647" height="36.114" viewBox="0 0 20.647 36.114">
               <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M17.475,24.246,31.139,10.592a2.581,2.581,0,0,0-3.655-3.645L12,22.418a2.576,2.576,0,0,0-.075,3.559L27.473,41.555a2.581,2.581,0,1,0,3.655-3.645Z" transform="translate(-11.251 -6.194)" fill="#665b76"/>
           </svg>
         </div> -->

                <!-- <div class="lowerShade">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1920 167">
              <defs>
                <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                  <stop offset="0" stop-color="#1f1233" stop-opacity="0" />
                  <stop offset="1" stop-color="#1f1233" />
                </linearGradient>
              </defs>
              <rect id="Rectangle_31" data-name="Rectangle 31" width="1920" height="167" fill="url(#linear-gradient)" />
            </svg>
          </div> -->
                <!-- <div class="rightarrow" @click="enableshowsDragger">
           <svg xmlns="http://www.w3.org/2000/svg" width="20.647" height="36.114" viewBox="0 0 20.647 36.114">
             <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M17.475,24.246,31.139,10.592a2.581,2.581,0,0,0-3.655-3.645L12,22.418a2.576,2.576,0,0,0-.075,3.559L27.473,41.555a2.581,2.581,0,1,0,3.655-3.645Z" transform="translate(31.897 42.308) rotate(180)" fill="#665b76"/>
           </svg>
         </div> -->
            <!-- </div> -->
        </div>
        <div class="listcontainer">
          <ul class="tvseriespagecontainer">
              <li
                  v-for="(item, index) in tvseriesApiResponse"
                  v-bind:key="index"
                  class="tvseriescontainer"
              >
                  <router-link
                      :to="{ name: 'tvshowsdetail', params: { id: item.id } }"
                  >
                      <img class="tvseriesimage" :src="item.image" />
                      <div class="tvseriestitle">
                          <span class="">{{ item.name }}</span>
                      </div>
                  </router-link>
              </li>
          </ul>
        </div>
        <FooterComponent></FooterComponent>
    </div>
</template>

<script>
/* eslint-disable */
import $ from "jquery";
import FooterComponent from "@/components/footer.vue";
import HeaderComponent from "@/components/header.vue";
export default {
    name: "tvshowsold",
    data() {
        return {
            tvSeriesBannersApiUrl:
                "https://api.kedutv.com/public/index.php/banners/sections/tvseries",
            bannerCallApiResponse: null,
            tvseriesApiUrl: "https://api.kedutv.com/public/index.php/category",
            tvseriesApiResponse: null,
            sliderInterval: null,
            showArrows: false,
            isloading: false,
        };
    },
    components: {
        FooterComponent,
        HeaderComponent,
    },
    async mounted() {
        await this.loadPageData();
    },
    methods: {
        loadPageData: function () {
            // Banners Call tv series
            var self = this;
            this.tvseriesBannersApi();
            this.setBannerSlider();
            //tvseries call
            this.tvseriesContentApi();
            setTimeout(function () {
                self.enableshowsDragger();
            }, 2000);
        },
        enableshowsDragger: function () {
            const slider = document.querySelector(".tvseriespagecontentrow");
            let isDown = false;
            let startX;
            let scrollLeft;

            slider.addEventListener("mousedown", (e) => {
                isDown = true;
                slider.classList.add("active");
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            });
            slider.addEventListener("mouseleave", () => {
                isDown = false;
                slider.classList.remove("active");
            });
            slider.addEventListener("mouseup", () => {
                isDown = false;
                slider.classList.remove("active");
            });
            slider.addEventListener("mousemove", (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 3; //scroll-fast
                slider.scrollLeft = scrollLeft - walk;
                console.log(walk);
            });
        },

        tvseriesBannersApi: function () {
            var self = this;
            this.isloading = true;
            var settings = {
                url: this.tvSeriesBannersApiUrl,
                method: "GET",
                timeout: 15000,
                processData: false,
                mimeType: "multipart/form-data",
                contentType: false,
            };
            $.ajax(settings)
                .done(function (response) {
                    self.bannerCallApiResponse = JSON.parse(response);
                    self.isloading = false;
                })
                .fail(function (xhr) {
                    self.isloading = false;
                    self.bannerCallApiResponse = null;
                    setTimeout(function () {
                        self.tvseriesBannersApi();
                    }, 5000);
                });
        },
        tvseriesContentApi: function () {
            var self = this;
            this.isloading = true;
            var form = new FormData();
            form.append("limit", "20");
            var settings = {
                url: this.tvseriesApiUrl,
                method: "POST",
                timeout: 15000,
                processData: false,
                mimeType: "multipart/form-data",
                contentType: false,
                data: form,
            };
            $.ajax(settings)
                .done(function (response) {
                    self.tvseriesApiResponse = JSON.parse(response);
                    self.isloading = false;
                })
                .fail(function (xhr) {
                    self.isloading = false;
                    self.tvseriesApiResponse = null;
                    setTimeout(function () {
                        self.tvseriesContentApi();
                    }, 5000);
                });
        },
        setBannerSlider: function () {
            if (this.sliderInterval == null)
                this.sliderInterval = setInterval(this.nextSliderItem, 5000);
        },
        resetBannerSlider: function () {
            clearInterval(this.sliderInterval);
            this.sliderInterval = null;
        },
        nextSliderItem: function () {
            if (this.bannerCallApiResponse != null) {
                var item = this.bannerCallApiResponse.shift();
                this.bannerCallApiResponse.push(item);
            }
        },
        perviousSliderItem: function () {
            if (this.bannerCallApiResponse != null) {
                var item = this.bannerCallApiResponse.pop();
                this.bannerCallApiResponse.unshift(item);
            }
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.tvseriespagecontainer{
    display: flex;
    justify-content: center;
    text-align: center;
    gap: 3rem;
    flex-wrap: wrap;
    margin: 0rem 7.5rem;
    border-radius: 16px;
}
.tvseriesimage{
    width:20rem
}
.tvseriestitle{
    color: white;
    margin-top: 1rem;
}
@media only screen and (min-width: 200px) and (max-width: 600px){
    .tvseriespagecontainer{
    display: flex;
    justify-content: center;
    text-align: center;
    gap: 3rem;
    flex-wrap: wrap;
    margin: 0rem 7.5rem 0rem 5rem;
}

.tvseriesimage{
    width:19rem;
}
}

.fullpage {
    overflow-x: hidden;
}

ul {
    list-style: none;
    list-style-type: none;
    float: left;
}


.tvseriesbanners {
    height: 22rem;
    display: flex;
    margin: 0% 12% 0% 11%;
}


/* @media screen and (max-width: 1000px) {
    .tvseriesbanners {
    height: 22rem;
    display: flex;
    margin: 0% 12% 0% 10%;
}
} */
.bannerlistvodtvlist div img {
    /* width: clamp(80%,50%,30%); */
    /* margin-right: 9vw; */
    width: 80vw;
    height: 22rem;
    border-radius: 1.6rem;
    position: absolute;
    border: solid;
    border-color: #4e4e4e;
}
@media only screen and (max-width: 600px) and (min-width: 300px) {
    .bannerlistvodtvlist div img {
    /* width: clamp(80%,50%,30%); */
    /* margin-right: 9vw; */
    width: 80vw;
    height: 16rem;
    border-radius: 1.6rem;
    position: absolute;
    border: solid;
    border-color: #4e4e4e;
    
}
.tvseriesbanners {
    height: 18rem;
    display: flex;
    margin: 0% 12% 0% 11%;
}
}

/* .bannerlistvodtvlist li {
    margin: auto;
} */

.spacer {
    height: 1.30rem;
}

.rightarrow {
    position: absolute;
    margin-top: 6.7rem;
    margin-left: 96%;
}

.lowerShade {
    position: absolute;
    margin-top: 12rem;
    width: 95%;
    overflow: hidden;
    pointer-events: none;
}

.tvseriespagecontentrow {
    width: 90%;
    height: 20rem;
    margin-left: 5rem;
    margin-right: 5rem;
    cursor: grab;
    display: flex;
    justify-content: center;
    overflow-x: scroll;
    overflow-y: hidden;
}
.listcontainer{
    display: flex;
    justify-content: center;
}

/* .tvseriespagecontenlist {
    width: -moz-max-content;
    width: -webkit-max-content;
    margin-left: 9%;
    margin-top: 20px;
    overflow-x: scroll;
    overflow: hidden;    
    overflow-y: hidden;
    display: flex;
    justify-content: center;
} */

/* .tvseriespagecontenlist li {
    width: 22rem;
    height: 18rem;
    display: flow-root;
    float: left;
    margin-right: 1.5rem;
    list-style-type: none;
} */

/* .tvseriespagecontenlist li a:hover + div span.itempagetext {
    cursor: pointer;
    color: #eb4664 !important;
} */

a {
    text-decoration: none;
}

/* .tvseriespagecontenlist li img {
    width: 22rem;
    height: 14rem;
    border-radius: 1.34rem;
    border: solid;
    position: absolute;
    border-width: 0.0469rem;
    border-color: #4e4e4e;
} */

/* .itempagetext {
    color: #fff;
    font-size: 16.08px;
    line-height: 1.273rem;
    overflow: hidden;
    font-family: MontBold;
    width: 16.75rem;
    white-space: nowrap;
    text-overflow: hidden;
} */

.leftarrow {
    position: absolute;
    margin-top: 6.7rem;
    margin-left: 1.34rem;
}

.contentsection {
    width: 100%;
    height: 20rem;
}

/* .titlefield {
    text-align: center;
    margin-top: 15rem;
}
.titlefield .itempagetext:hover {
    color: #eb4664;
} */

/* Hide scrollbar for Chrome, Safari and Opera */
.tvseriespagecontentrow::-webkit-scrollbar {
    display: none;
}
</style>
