import Vue from 'vue'
import App from './App'
import router from './router/index.js'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { VueQueryPlugin } from '@tanstack/vue-query'
import VueSocialSharing from 'vue-social-sharing'
import '../src/assets/styles/app.css'
// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make tanstack Vue available throughout your project
Vue.use(VueQueryPlugin)
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
// 
Vue.use(VueSocialSharing);
Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>'
})
