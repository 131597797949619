import Vue from 'vue'
import VueRouter from 'vue-router';
import home from '@/components/home.vue'
import tvshowsdetail from '@/components/tvshowsdetail.vue'
import tvshows from '@/components/tvshows.vue'
import tvshowsold from '@/components/tvshowsold.vue'
import news from '@/components/news.vue'
import newsdetail from '@/components/newsdetail.vue'
import singlevideo from '@/components/singlevideo.vue'
import contact from '@/components/contact.vue'
import aboutus from '@/components/aboutus.vue'
import disclaimer from '@/components/disclaimer.vue'
import termofservice from '@/components/termofservice.vue'
import termofuse from '@/components/termofuse.vue'
import privacypolicy from '@/components/privacypolicy.vue'
import copyright from '@/components/copyright.vue'

/* eslint-disable */
Vue.use(VueRouter)
export default new VueRouter({
  routes: [
    {
      path: "/",
      name: "home",
      component: home,
    },
    {
      path: "/tvshowsdetail/:id",
      name: "tvshowsdetail",
      component: tvshowsdetail,
    },
    {
      path: "/tvshowsold",
      name: "tvshowsold",
      component: tvshowsold,
    },
    {
      path: "/tvshows",
      name: "tvshows",
      component: tvshows,
    },
    {
      path: "/news",
      name: "news",
      component: news,
    },
    {
      path: "/singlevideo",
      name: "singlevideo",
      component: singlevideo,
    },
    {
      path: "/newsdetail",
      name: "newsdetail",
      component: newsdetail,
    },
    {
      path: "/contact",
      name: "contact",
      component: contact,
    },
    {
      path: "/aboutus",
      name: "aboutus",
      component: aboutus,
    },
    {
      path: "/disclaimer",
      name: "disclaimer",
      component: disclaimer,
    },
    {
      path: "/termofservice",
      name: "termofservice",
      component: termofservice,
    },
    {
      path: "/termofuse",
      name: "termofuse",
      component: termofuse,
    },
    {
      path: "/privacypolicy",
      name: "privacypolicy",
      component: privacypolicy,
    },
    {
      path: "/copyright",
      name: "copyright",
      component: copyright,
    },
    {
      path: "*",
      redirect: "/"
    }
  ],
  mode: 'history',
})