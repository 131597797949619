function convertToHtml(description) {
    let html = '';
    try {
        description = JSON.parse(description)
    } catch (error) { console.log(error); }

    for (const block of description.blocks) {
        switch (block.type) {
            case 'paragraph': {
                // Check the alignmentBlockTune for alignment
                const alignment = block.tunes.alignmentBlockTune.alignment;
                const alignmentClass = alignment ? `text--align--${alignment}` : ''; // Check if alignment is provided
                html += `<p class="paragraph ${alignmentClass}">${block.data.text}</p>`;
                break;
            }
            case 'header': {
                const alignment = block.tunes.alignmentBlockTune.alignment;
                const alignmentClass = alignment ? `text--align--${alignment}` : ''; // Check if alignment is provided
                html += `<h${block.data.level} class="header ${alignmentClass}">${block.data.text}</h${block.data.level}>`;
                break;
            }
            case 'raw': {
                html += block.data.html;
                break;
            }
            case 'list': {
                const listType = block.data.style === 'ordered' ? 'ol' : 'ul';
                const itemsHtml = block.data.items.map(item => `<li>${item}</li>`).join('');
                html += `<${listType} class="list">${itemsHtml}</${listType}>`;
                break;
            }
            case 'quote': {
                const alignment = block.tunes.alignmentBlockTune.alignment;
                const alignmentClass = alignment ? `text--align--${alignment}` : ''; // Check if alignment is provided
                html += `<blockquote class="quote ${alignmentClass}">${block.data.text}<footer>${block.data.caption}</footer></blockquote>`;
                break;
            }
            case 'table': {
                const tableHtml = block.data.content.map(row => `<tr>${row.map(cell => `<td>${cell}</td>`).join('')}</tr>`).join('');
                html += `<table class="table">${tableHtml}</table>`;
                break;
            }
            case 'alert': {
                const alignment = block.tunes.alignmentBlockTune.alignment;
                const alignmentClass = alignment ? `text--align--${alignment}` : ''; // Check if alignment is provided
                const alertType = block.data.type || 'primary'; // Default to 'primary' if type is not provided
                html += `<div class="alert alert-${alertType} ${alignmentClass}">${block.data.message}</div>`;
                break;
            }
            case 'warning': {
                const alignment = block.tunes.alignmentBlockTune.alignment;
                const alignmentClass = alignment ? `text--align--${alignment}` : ''; // Check if alignment is provided
                html += `<div class="warning ${alignmentClass}"><strong>${block.data.title}</strong>: ${block.data.message}</div>`;
                break;
            }
            case 'image': {
                const withBorder = block.data.withBorder || false;
                const stretched = block.data.stretched || false;
                // const withBackground = block.data.withBackground || false;
                const image_url = block.data.file.url
                const filename = image_url.split('/').at(-1)
                html += `<div class="edjs--image"><img src="${image_url}" alt="${filename}"`;
                if (withBorder) {
                    html += ` class="with-border"`;
                }

                if (stretched) {
                    html += ` class="stretched"`;
                }

                html += ` /></div>`;
                if (block.data.caption) {
                    html += `<p class="image-caption">${block.data.caption}</p>`;
                }
                break;
            }
            // Add more cases for other block types as needed
            default: {
                // Handle unsupported block types or skip them
                break;
            }
        }
    }
    return html
}

export { convertToHtml }