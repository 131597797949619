<template>
    <div>
        <div v-if="this.isPlayerOverlay === false">
            <div class="background-image" v-if="contentItemData != null" :style="{
                backgroundImage:
                    'url(' +
                    [
                        contentItemData != null
                            ? contentItemData.content[parseInt(currentCategoryItemIndex)][
                                parseInt(currentSeasonItemIndex)
                            ].background_image
                            : '',
                    ] +
                    ')',
            }">
                <div class="overlay-image">
                    <HeaderComponent></HeaderComponent>
                    <!-- <div class="logo-section">
            <div class="left-logo-section">
            <img src="../assets/images/logo.png" alt="" />
            </div>
            <div class="right-logo-section">
            <router-link class="home-logo" to="/">
                <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" fill="currentColor"
                class="bi bi-house-door" viewBox="0 0 16 16">
                <path
                    d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146ZM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5Z" />
                </svg><span>HOME</span>
            </router-link>
            <router-link class="tvshows-logo" to="/">
                  <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" fill="currentColor" class="bi bi-play-btn"
                  viewBox="0 0 16 16">
                  <path d="M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
                  <path
                      d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                  </svg>
                  <span>TV SHOWS</span>
            </router-link>
            </div>
        </div> -->
                    <div class="main-content">
                        <div class="title">
                            {{ contentItemData.name }}
                        </div>
                        <div class="meta">
                            <span class="iconvalue">
                                {{
                                    contentItemData.content[parseInt(currentCategoryItemIndex)][
                                        parseInt(currentSeasonItemIndex)
                                    ].display_name
                                }}
                                |
                            </span>
                            <span class="iconvalue">
                                <img width="21" height="21" src="../assets/images/durationicon.png" />{{
                                    contentItemData.content[parseInt(currentCategoryItemIndex)][
                                        parseInt(currentSeasonItemIndex)
                                    ].duration
                                }}
                                |
                            </span>
                            <span class="iconvalue">
                                <img width="21" height="21" src="../assets/images/yearicon.png" />{{
                                    contentItemData.content[parseInt(currentCategoryItemIndex)][
                                        parseInt(currentSeasonItemIndex)
                                    ].release_year
                                }}
                                |
                            </span>
                            <span class="iconvalue">
                                <img width="21" height="21" src="../assets/images/ratingicon.png" />
                                {{ contentItemData.rating }}
                            </span>
                        </div>
                        <b class="high-light">Overview</b>
                        <div class="description">
                            {{ contentItemData.description }}
                            <br />
                            {{
                                contentItemData.content[parseInt(currentCategoryItemIndex)][
                                    parseInt(currentSeasonItemIndex)
                                ].description
                            }}
                        </div>

                        <div class="buttons" @click="
                                playContent(
                                    parseInt(currentCategoryItemIndex),
                                    parseInt(currentSeasonItemIndex)
                                )
                                    ">
                                <button class="watch-now">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                        class="bi bi-play-fill" viewBox="0 0 16 16">
                                        <path
                                            d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
                                    </svg>
                                    Watch Now
                                </button>
                            <!-- <div>
                                    <button class="more-episodes">More Episodes</button>
                                </div> -->
                        </div>
                        <b class="high-light">Cast and Crew</b><br />
                        {{
                            contentItemData.content[parseInt(currentCategoryItemIndex)][
                                parseInt(currentSeasonItemIndex)
                            ].cast
                        }}
                        {{
                            contentItemData.content[parseInt(currentCategoryItemIndex)][
                                parseInt(currentSeasonItemIndex)
                            ].director
                            ? " | " +
                            contentItemData.content[parseInt(currentCategoryItemIndex)][
                                parseInt(currentSeasonItemIndex)
                            ].director
                            : ""
                        }}
                        {{
                            contentItemData.content[parseInt(currentCategoryItemIndex)][
                                parseInt(currentSeasonItemIndex)
                            ].production_company
                            ? " | " +
                            contentItemData.content[parseInt(currentCategoryItemIndex)][
                                parseInt(currentSeasonItemIndex)
                            ].production_company
                            : ""
                        }}
                    </div>
                    <div class="seasons" v-for="(items, key) in contentItemData.content" :key="key"
                        v-show="currentCategoryItemIndex == key">
                        <div class="seasons--title--info">
                            <h5>season</h5>
                            <span v-for="(s, idx) in seasons" :key="idx">
                                <span class="season_no" @click="handleSeason(s)">{{ s }}</span>
                            </span>
                        </div>
                        <button class="left--arrow--button" @click="handleScroll(currentCategoryItemIndex, 'left')">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                                class="bi bi-arrow-left" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                            </svg>
                        </button>
                        <button class="right--arrow--button" @click="handleScroll(currentCategoryItemIndex, 'right')">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                                class="bi bi-arrow-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                            </svg>
                        </button>
                        <div 
                            class="allseasons tvseriesdetaillistitem"
                            @pointerdown="handlePointerOnSeason"
                            @pointerup="handlePointerOnSeason"
                            v-if="contentItemData.content">
                            <span class="episodes" v-for="(item, index) in items" v-bind:key="index"
                                @mouseover="updateIndex(key, index)" @click="playContent(key, index)">
                                <img :src="item.image" />
                            </span>
                        </div>
                    </div>
                </div>
                <FooterComponent></FooterComponent>
            </div>
        </div>
        <div class="player" v-else-if="this.isPlayerOverlay">
            <button class="go-back" @click="closePlayer()">go back</button>
            <fluid-player :src="src" v-if="src" :vast_ad="vast_ad" />
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import $ from "jquery";
import { mounted } from "vue";
// import Hls from 'hls.js';
import FluidPlayer from "./fluidplayer.vue";
import FooterComponent from "@/components/footer.vue";
import HeaderComponent from "@/components/header.vue";
export default {
    name: "tvshowsdetailold",
    data() {
        return {
            selectedContentId: this.$route.params.id,
            seasons: [],
            currentSeasonItemIndex: 0,
            currentCategoryItemIndex: 0,
            ApiUrl: "https://api.kedutv.com/public/index.php/category/",
            contentItemData: null,
            ratingBtn: false,
            showArrows: false,
            ratingMsg: "Thanks For Rating",
            isRated: false,
            isloading: false,
            seekTo: false,
            isPlaying: true,
            playbarTimer: null,
            isPlayerOverlay: false,
            showPlayBar: true,
            videoTotalTime: "00:00:00",
            videoCurrentTime: "00:00:00",
            videoSeekToTime: "00:00:00",
            videoTotalTimeinSeconds: 0,
            videoCurrentTimeinSeconds: 0,
            imageUrl: null,
            src: null,
            vast_ad: null,
        };
    },
    components: {
        FooterComponent,
        FluidPlayer,
        HeaderComponent,
    },
    async mounted() {
        await this.loadPageData();
    },
    methods: {
        handleSeason: function (s) {
            this.currentCategoryItemIndex = s;
        },
        loadPageData: function () {
            // var self = this;
            this.categoryCall();
            // setTimeout(function () {
            //     self.enableshowEpisodesDragger();
            //     self.leftrightscroll();
            // }, 2000);
        },
        categoryCall: function () {
            var self = this;
            this.isloading = true;
            var settings = {
                url: this.ApiUrl + this.selectedContentId,
                method: "GET",
                timeout: 15000,
                processData: false,
                mimeType: "multipart/form-data",
                contentType: false,
            };
            $.ajax(settings)
                .done(function (response) {
                    self.contentItemData = JSON.parse(response);
                    self.seasons = Object.keys(self.contentItemData.content);
                    self.currentCategoryItemIndex = self.seasons.at(0);
                    self.isloading = false;
                })
                .fail(function (xhr) {
                    self.isloading = false;
                    self.contentItemData = null;
                    setTimeout(function () {
                        self.categoryCall();
                    }, 5000);
                });
        },
        handleScroll: function (idx, type) {
            const allSeasonsContainers = document.querySelectorAll(".allseasons")[idx - 1]
            if (type === 'left') {
                allSeasonsContainers.scrollLeft -= 315;
            } else {
                allSeasonsContainers.scrollLeft += 315;
            }
        },
        handlePointerOnSeason: function (e) {
            // Find the corresponding allseasons container for the current category item
            const allSeasonsContainers = this.$el.querySelectorAll(".allseasons");
            const currentContainer = allSeasonsContainers[this.currentCategoryItemIndex - 1];
            if (!currentContainer) return;
            let startX = e.pageX - currentContainer.offsetLeft;
            let scrollLeft = currentContainer.scrollLeft;
            e.preventDefault();
            const x = e.pageX - currentContainer.offsetLeft;
            const walk = (x - startX) * 2; //scroll-fast
            currentContainer.scrollLeft = scrollLeft - walk;
        },
        // enableshowEpisodesDragger: function () {
        //     const slider = document.querySelectorAll(".tvseriesdetaillistitem")[this.currentCategoryItemIndex-1];
        //     let isDown = false;
        //     let startX;
        //     let scrollLeft;
        //     if (slider) {
        //         slider.addEventListener("mousedown", (e) => {
        //             isDown = true;
        //             slider.classList.add("active");
        //             startX = e.pageX - slider.offsetLeft;
        //             scrollLeft = slider.scrollLeft;
        //         });
        //         slider.addEventListener("mouseleave", () => {
        //             isDown = false;
        //             slider.classList.remove("active");
        //         });
        //         slider.addEventListener("mouseup", () => {
        //             isDown = false;
        //             slider.classList.remove("active");
        //         });
        //         slider.addEventListener("mousemove", (e) => {
        //             if (!isDown) return;
        //             e.preventDefault();
        //             const x = e.pageX - slider.offsetLeft;
        //             const walk = (x - startX) * 2; //scroll-fast
        //             slider.scrollLeft = scrollLeft - walk;
        //         });
        //     }
        // },
        closePlayer: function () {
            this.isPlaying = false;
            this.isPlayerOverlay = false;
            // clearInterval(this.playbarTimer);
        },
        playContent: function (currentSeasonIndex, currentEpisodeIndex) {
            this.currentCategoryItemIndex = currentSeasonIndex;
            this.currentSeasonItemIndex = currentEpisodeIndex;
            this.playbarTimer = setInterval(this.checkPlayBar, 10000);
            let self = this;
            setTimeout(function () {
                // let hls = new Hls();
                self.src =
                    self?.contentItemData?.content[self?.currentCategoryItemIndex][
                        self?.currentSeasonItemIndex
                    ]?.link;
                self.vast_ad =
                    self?.contentItemData?.content[self?.currentCategoryItemIndex][
                        self?.currentSeasonItemIndex
                    ]?.vast_ad;
                // console.log(stream);
                // let video = document.querySelector('video');
                // hls.loadSource(stream);
                // hls.attachMedia(video);
            }, 1000);
            if (this.src) this.isPlayerOverlay = true;
        },
        updateIndex: function (currentSeasonIndex, currentEpisodeIndex) {
            this.currentCategoryItemIndex = currentSeasonIndex;
            this.currentSeasonItemIndex = currentEpisodeIndex;
        },
        toggleFullscreen: function () {
            setTimeout(function () {
                var elem = document.querySelector("video");
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) {
                    /* Safari */
                    elem.webkitRequestFullscreen();
                } else if (elem.msRequestFullscreen) {
                    /* IE11 */
                    elem.msRequestFullscreen();
                }
            }, 1000);
        },
        nextItemBtn: function () {
            this.resetPlayer();
            var totalItemsInSeason = Object.keys(
                this.contentItemData.content[parseInt(this.currentCategoryItemIndex)]
            ).length;
            if (this.currentSeasonItemIndex < totalItemsInSeason - 1) {
                this.currentSeasonItemIndex += 1;
                setTimeout(function () {
                    $("#videoPlayer").get(0).load();
                    $("#videoPlayer").get(0).play();
                }, 1000);
            } else {
                if (
                    this.currentCategoryItemIndex <
                    Object.keys(this.contentItemData.content).length - 1
                ) {
                    this.currentCategoryItemIndex += 1;
                    this.currentSeasonItemIndex = 0;
                    setTimeout(function () {
                        $("#videoPlayer").get(0).load();
                        $("#videoPlayer").get(0).play();
                    }, 1000);
                } else {
                    this.resetPlayer();
                    this.isPlayerOverlay = false;
                }
            }
        },
        videoEnded: function () {
            this.togglePlayBar();
            // this.nextItemBtn();
        },
        loadMetaData: function (e) {
            var videoDuration = Math.round(e.target.duration);
            this.videoTotalTimeinSeconds = videoDuration;
            var time = this.formatTime(videoDuration);
            this.videoTotalTime =
                time.hours + ":" + time.minutes + ":" + time.seconds;
        },
        currentTimeUpdate: function (e) {
            var currentTime = Math.round(e.target.currentTime);
            this.videoCurrentTimeinSeconds = currentTime;
            var time = this.formatTime(currentTime);
            this.videoCurrentTime =
                time.hours + ":" + time.minutes + ":" + time.seconds;
        },
        formatTime: function (timeInSeconds) {
            var result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8);
            return {
                hours: result.substr(0, 2),
                minutes: result.substr(3, 2),
                seconds: result.substr(6, 2),
            };
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.player {
    height: 95dvh;
}

.go-back {
    all: unset;
    color: white;
    position: absolute;
    cursor: pointer;
    z-index: 5;
    top: 1rem;
    left: 1rem;
}

.background-image {
    /* background-image: url("https://images.unsplash.com/photo-1679674704818-f3a500c1305b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100%;
}

.overlay-image {
    background-image: url("../assets/images/shade.png");
    background-size: cover;
    background-position: center;
    background-blend-mode: overlay;
    min-height: 100dvh;
    width: 100%;
    background-repeat: no-repeat;
}

.logo-section {
    font-family: sans-serif;
    padding: 2rem 2% 0rem 2%;
    display: flex;
    justify-content: space-between;
}

.left-logo-section img {
    height: auto;
    width: 7.5rem;
}

.home-logo span,
.tvshows-logo span {
    color: white;
    position: relative;
    top: 0.4rem;
    margin-left: 0.3rem;
    /* bottom: 0.75rem; */
    font-size: 1.5rem;
}

.home-logo svg,
.tvshows-logo svg {
    color: white;
    position: relative;
    font-size: 1.5rem;
}

.tvshows-logo svg,
.tvshows-logo span {
    color: indianred;
}

.right-logo-section {
    display: flex;
    gap: 3rem;
}

.main-content {
    color: white;
    font-family: sans-serif;
    margin: 0px 10% 0% 10%;
    padding-top: 8%;
    line-height: 2rem;
}

.main-content .title {
    font-family: "Pacifico", cursive;
    font-size: 2rem;
}

.main-content .meta {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.1rem;
    margin-top: 2rem;
    gap: 1rem;
}

.main-content .meta .iconvalue {
    display: inline-flex;
    gap: 0.3rem;
    justify-content: center;
    align-items: center;
}

.main-content .description {
    font-size: 1.05rem;
    line-height: 1.5rem;
}

.main-content .watch-now {
    color: white;
    background: linear-gradient(180deg,
            rgba(162, 48, 100, 1) 0%,
            rgba(230, 81, 32, 1) 50%,
            rgb(65, 15, 85) 90%,
            rgba(0, 0, 0, 1) 100%);
    font-size: 1.5rem;
    padding: 0.5rem 0.8rem;
    border-radius: 4rem;
    font-weight: 600;
    border: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.high-light {
    font-weight: bold;
    font-size: 1.3rem;
}

.main-content .watch-now svg {
    position: relative;
}

.main-content .more-episodes {
    color: black;
    background: white;
    font-size: 0.9rem;
    padding: 0.5rem 1.5rem;
    border-radius: 1rem;
    font-weight: 600;
    border: none;
}

.left--arrow--button {
    position: absolute;
    margin-top: 2.5rem;
    left: calc(8% + 1.5rem);
    color: black;
    background: white;
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    font-weight: 600;
    border: none;
}

.right--arrow--button {
    position: absolute;
    margin-top: 2.5rem;
    right: calc(8% + 1.5rem);
    color: black;
    background: white;
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    font-weight: 600;
    border: none;
}

.main-content .buttons {
    display: flex;
    gap: 1rem;
    margin: 1rem 0 1rem 0;
}

.seasons {
    margin-left: 8%;
    margin-top: 4rem;
}

.seasons--title--info {
    color: white;
    margin-bottom: 4rem;
}

.seasons--title--info .season_no {
    cursor: pointer;
    border: 2px solid white;
    padding: 4px 11px;
    margin-right: 1rem;
}

.seasons--title--info h5 {
    margin-bottom: 1rem;
}

.seasons--title--info .season_no:hover {
    background-color: indianred;
}

.allseasons {
    display: flex;
    gap: 1rem;
    overflow-x: scroll;
    cursor: grab;
    padding-bottom: 2rem;
    margin-left: 4rem;
    margin-right: calc(8% + 4rem);
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.allseasons img {
    height: 9.375rem;
    width: auto;
    border-radius: 1rem;
}

.allseasons::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    display: none;
}

/* 
.allseasons::-webkit-scrollbar-track {
  background: #1F1233;
}

.allseasons::-webkit-scrollbar-thumb {
  background-color: #AB093E;
  border-radius: 10px;
}

.allseasons::-webkit-scrollbar-thumb:hover {
  background-color: white;
} */

@media only screen and (max-width: 500px) {
    .home-logo {
        display: none;
    }

    .tvshows-logo span {
        font-size: 1rem;
    }

    .main-content .buttons {
        flex-direction: column;
    }

    .main-content .title {
        font-size: 1.75rem;
    }

    .allseasons img {
        height: 8rem;
    }

    .home-logo span,
    .tvshows-logo span {
        bottom: 1rem;
    }
}
</style>
