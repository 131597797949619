<template>
    <div class="about">
        <div class="fullpage">
            <HeaderComponent></HeaderComponent>
            <div class="content">
                <h1 class="text-center">About US</h1>
                <div class="description">
                    <p>
                        The story of KeduTV began when a friend visited the
                        founder and inquired why he was always in the basement
                        of his home. A conversation soon ensued about the
                        inspiring success of WhatsApp and how its founders
                        started off by creating the app in their home before
                        successfully selling it to FaceBook, now Meta, for
                        billions of dollars. The founder was encouraged by his
                        friend to create something great during his time in the
                        basement.
                    </p>
                    <p>
                        That conversation soon ignited a spark that started an
                        unquenchable, passionate, fiery dream; a dream so great
                        that with dedication and determination, it became the
                        reality that is KeduTV.
                    </p>
                    <p>
                        KeduTV was founded by Nigerian American, Lasbrey Jnr
                        Akamike in December 2014 from his basement in Virginia.
                        Its unique and easy-to-pronounce name was provided by
                        the founder’s father. In the Igbo language of Nigeria,
                        “kedu” translates to “hello” or in a bid to show care,
                        it could translate to “how are you?” That night, the
                        domain name kedu.tv was born and in August 2018, it was
                        changed to kedutv.com.
                    </p>
                    <p>
                        KeduTV was created to celebrate and provide easy access
                        to current and vibrant African Music, popularly known as
                        Afrobeats to the rest of the world. As per our motto,
                        “BRIDGING BORDERS”, we have since embarked on a mission
                        to unite millions of viewers across the globe by
                        providing them with quality entertainment and top-notch
                        African music.
                    </p>
                    <p>
                        From December 24th to 26th 2014, Lasbrey Jnr Akamike
                        streamed a test run for KeduTV on the UStream platform
                        which was later sold to Twitch in 2016. In 2016, KeduTV
                        moved the streaming signal to MyTVAfrique, Ghana Online
                        and many IPTV Platforms but subsequently left in
                        December 2017. In August 2018, the founder partnered
                        with Stanley Uche Nwoke and KeduTV became an
                        incorporated and registered company in that same month.
                        Thus, KeduTV LLC was established, and the streaming
                        station became KeduTV Channel to differentiate the
                        product provider from the corporation.
                    </p>
                    <p>
                        In 2018, the KeduTV founder created the independence of
                        the channel’s self-sufficient ability to stream within
                        the organization and then branched the business to a
                        limitless ability to run streaming independently. This
                        makes KeduTV Channel one of the independent IPTV
                        Afro-Entertainment streaming platforms in the world.
                    </p>
                    <p>
                        In October 2018, KeduTV Channel was officially launched
                        on Roku Devices in 18 countries. In September 2020, the
                        vision expanded and KeduTV Channel was launched on LG
                        Smart TV, reaching 234 countries worldwide. The next
                        month, the channel was launched on Samsung USA. By the
                        following year, in February 2021, it was also launched
                        on Samsung Worldwide, reaching a total of 196 countries.
                        In March 2022, KeduTV once again broadened its reach and
                        was launched on Amazon FireTV, reaching 5 countries; the
                        USA, UK, Canada, Germany, and Japan. This move ensured
                        more accessibility for the channel’s audience as the
                        Amazon FireTV stick also works as a portable traveling
                        device which can be used in any country around the world
                        with internet access. In the same March of 2022, KeduTV
                        launched its mobile app for Android and iOS devices,
                        effectively making the world of Afro-entertainment only
                        one click away.
                    </p>
                    <p>
                        KeduTV Channel is regarded as one of the pioneers in
                        creating awareness and educating numerous people about
                        the magic of African Music. The organization continues
                        to grow into a global TV channel and has become a
                        thriving entertainment hub, sharing with its audience
                        the latest trends in music, lifestyle, fashion, culture,
                        entertainment, locations and the vast beauty of Africa.
                    </p>
                    <p>
                        KeduTV Channel is available for download on Samsung
                        Smart TV, Roku, FireTV, LG Smart TV, iPhone and all
                        Android mobile devices.
                    </p>
                </div>
                <div class="findus row">
                    <ul class="list col-12">
                        <li class="listitems">
                            <a
                                href="https://www.samsung.com/us/appstore/app/G20242015438"
                                target="_blank"
                            >
                                <img
                                    src="https://imagedelivery.net/mGggG9K1_GoffhfXMddRng/5ded05bf-f090-4d99-8be2-03fad579e900/public"
                                    alt="samsung smart tv"
                                />
                            </a>
                        </li>
                        <li class="listitems">
                            <a
                                href="https://channelstore.roku.com/details/61290088c6cfcecaf1f7263c0c6f731e/kedutv"
                                target="_blank"
                            >
                                <img
                                    src="https://imagedelivery.net/mGggG9K1_GoffhfXMddRng/c65ea69d-640c-4881-f7a4-3ef7fe24f500/public"
                                    alt="roku"
                                />
                            </a>
                        </li>
                        <!-- <li class="listitems">
              <a href="https://www.amazon.com/KeduTV/dp/B09TY8QV3V/ref=sr_1_1?keywords=kedutv&qid=1661565109&s=mobile-apps&sr=1-1"
                target="_blank">
                <img src="https://www.kedutv.com/wp-content/uploads/2022/03/tv-logo-7.png" alt="amazon fire" />
              </a>
            </li> -->
                        <li class="listitems">
                            <a
                                href="https://us.lgappstv.com/main/tvapp/detail?appId=1038702&catCode1=&moreYn=N&cateYn=N&orderType=0&headerName=&appRankCode=&sellrUsrNo="
                                target="_blank"
                            >
                                <img
                                    src="https://imagedelivery.net/mGggG9K1_GoffhfXMddRng/26728e00-929f-44bd-89f1-b42154ea8d00/public"
                                    alt="lg tv"
                                />
                            </a>
                        </li>
                        <li class="listitems">
                            <a
                                href="https://apps.apple.com/eg/app/kedutv-app/id1611606321"
                                target="_blank"
                            >
                                <img
                                    src="https://imagedelivery.net/mGggG9K1_GoffhfXMddRng/6623ff73-36a1-4e3e-5882-7b2e549b7300/public"
                                    alt="iphone"
                                />
                            </a>
                        </li>
                        <li class="listitems">
                            <a
                                href="https://play.google.com/store/apps/details?id=com.app.kedu_tv&hl=en_US&gl=US"
                                target="_blank"
                            >
                                <img
                                    src="https://imagedelivery.net/mGggG9K1_GoffhfXMddRng/c05e2074-2fde-4051-ea87-70960aed6d00/public"
                                    alt="andriod"
                                />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <FooterComponent></FooterComponent>
    </div>
</template>

<script>
/* eslint-disable */
import FooterComponent from "@/components/footer.vue";
import HeaderComponent from "@/components/header.vue";
export default {
    name: "aboutus",
    data() {
        return {
            title: "about us",
        };
    },
    components: {
        FooterComponent,
        HeaderComponent,
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logoleft {
    margin-left: 3%;
    margin-top: 10px;
    width: 15%;
    height: 3rem;
    background: url(../assets/images/logo.png) no-repeat;
    background-size: contain;
    float: left;
}

.fullpage {
    overflow-x: hidden;
}

.menues {
    float: right;
    width: 100%;
}

ul {
    list-style: none;
    list-style-type: none;
    float: left;
}

.menues ul {
    float: right;
}

.menues ul li {
    display: inline-table;
    padding-right: 40px;
    margin-top: 10px;
}

.menues ul li a {
    text-decoration: none;
    font-size: 0.9rem;
    color: #d3d0d8;
}

.menues ul li a:hover {
    text-decoration: none;
    color: #eb4664;
}

.menues ul li a:active {
    text-decoration: none;
    color: #ffffe0;
}

.content {
    float: left;
    margin-top: 2rem;
    color: #ffffe0;
    text-align: justify;
    margin: 2rem;
}

.description {
    margin-left: 2rem;
    margin-right: 2rem;
}

.listitems {
    float: left;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.list {
    margin-left: 5%;
    margin-right: 5%;
}

.findus {
    text-align: center;
    width: 100%;
}
</style>
