<template>
    <div class="singlevideo" v-if="videoUrl">
        <FluidPlayerComponent :vast_ad="vastAd" :src="videoUrl"  />
      </div>
    <div class="singlevideo" v-else-if="!videoUrl">
    <FluidPlayerComponent/>
    </div>
  </template>
  
  <script>
  /* eslint-disable */
import FluidPlayerComponent from '@/components/fluidplayer.vue'
  export default {
    name: 'SingleVideo',
    props: {
        // Component props go here
    },
    data() {
        return {
            // Component data goes here
            title: "single video",
            videoUrl: null,
            vastAd: null
        }
    },
    components: {
        // Components initialization goes here
        FluidPlayerComponent,
  },
  mounted() {
    this.videoUrl = this.$route.query?.url ? decodeURIComponent(this.$route.query?.url) : null
    this.vastAd = this.$route.query?.vastAd ? decodeURIComponent(this.$route.query?.vastAd) : null
    console.log({videoUrl:this.videoUrl,vastAd :this.vastAd})
    window.onload= function () {
      document.querySelector('video').play();
    }
  },
  created() {
  },
  methods: {
    
},
  }
  </script>
  
  <style scoped>
  /* Component styles go here */
.singlevideo{
        width: auto;
        height: 100vh;
        overflow: hidden;
}
  </style>
  