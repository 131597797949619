<template>
  <div class="about">
    <div class="fullpage">
      <HeaderComponent></HeaderComponent>
      <div class="content">
        <h1 class="text-center">Copyright Notice</h1>
        <div class="description">
          <p>All files and information contained in this Website located at https://kedutv.com are copyright by KeduTV
            LLC, and may not be duplicated, copied, modified or adapted, in any way without our written permission. Our
            Website may contain our service marks or trademarks as well as those of our affiliates or other companies,
            in the form of words, graphics, and logos.</p>
          <p>Your use of our Website or Services does not constitute any right or license for you to use our service
            marks or trademarks, without the prior written permission of KeduTV LLC.</p>
          <p>Our Content, as found within our Website, Website and Services, is protected under local and foreign
            copyrights. The copying, redistribution, use or publication by you of any such Content, is strictly
            prohibited. Your use of our Website and Services does not grant you any ownership rights to our Content.</p>
          <h2 >Enforcement of copyright</h2>
          <p>KeduTV LLC takes the protection of its copyright very seriously.</p>
          <p>If KeduTV LLC discovers that you have used its copyright materials in contravention of the license above,
            KeduTV LLC may bring legal proceedings against you seeking monetary damages and an injunction to stop you
            using those materials. You could also be ordered to pay legal costs.</p>
          <p>If you become aware of any use of KeduTV LLC’s copyright materials that contravenes or may contravene the
            license above, please report this to us immediately.</p>
        </div>
      </div>
    </div>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
/* eslint-disable */
import FooterComponent from '@/components/footer.vue'
import HeaderComponent from '@/components/header.vue'
export default {
  name: 'copyright',
  data() {
    return {
      title: 'copyright'
    }
  },
  components: {
    FooterComponent,
    HeaderComponent
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.fullpage {
  overflow-x: hidden;
}

ul {
  list-style: none;
  list-style-type: none;
  float: left;
}


.content {
  float: left;
  margin-top: 2rem;
  color: #FFFFE0;
  text-align: justify;
  margin: 2rem;
}

.description {
  margin-left: 2rem;
  margin-right: 2rem;
}

.listitems {
  float: left;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.list {
  margin-left: 5%;
  margin-right: 5%;
}

.findus {
  text-align: center;
  width: 100%;
}
</style>
