import { ref } from 'vue'

function useQueryParams() {
  const queryParams = ref({})

  const updateQueryParams = () => {
    const queryString = window.location.search
    const params = new URLSearchParams(queryString)
    queryParams.value = Object.fromEntries(params.entries())
  }

  updateQueryParams()

  window.addEventListener('popstate', updateQueryParams)

  return queryParams
}

export {useQueryParams};